import React, { Component } from 'react'

import style from './style.module.css'


const placeholder = 'https://pbs.twimg.com/media/DgQd06aUwAAUDH6?format=jpg&name=large'

class Standard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
        
    }

    componentDidMount = async () => {

       
      }

  
   

    render () {

       

            
return (

    
   
        <div className={style.container}>
            <div className={style.header}>
                <div className={style.username}>
                LukaStanDanTheMan
                </div>
                <div className={style.timestamp}>
                3 Hours Ago
                </div>
                <div className={style.rightHeaderContainer}>
                    <div className={style.agreeButton}>
                        Agree
                    </div>
                    <div className={style.agreeButton}>
                        Disagree
                    </div>
                    <div className={style.rectButton}>
                        Ride or Die
                    </div>
                </div>
            </div>
            <div className={style.badgeContainer}>
            <img className={style.userBadge} src={placeholder}/>
            </div>
            <div className={style.contentBox}>
                <div className={style.contentLongAnswer}>
                    I dont think westbrook is that good because ninja turtles cant really be point guards in the nba if you really think about it that is why draymond green hit his ceiling so early the ninja turtle thing really held him back and now ia m just goin to type a bunch of ish to make this a longer answer to make sure the answer cell is scaling correctly ok let us see
                </div>
            </div>
        </div>
      
    
  )
  }
  }

export default Standard
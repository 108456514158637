import React, { Component, useState, useEffect } from 'react'

import style from './style.module.css'

import { SubmitModal, Standard, RightBar } from '../../components'


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation
  } from "react-router-dom";



const placeholder = 'https://cdn.shopify.com/s/files/1/0915/3014/products/0544_basketball_1_large.jpg?v=1581108009'


export default function Debate () {
  
   
    const location = useLocation()

    let debateInfo = {}
    if(location.state){
    debateInfo = location.state
    }

    const [isSubmitting, setIsSubmitting] = useState(false)
    const buttonHandler = () => {
    setIsSubmitting(current => !current)
    }

    useEffect( () => {
    console.log(isSubmitting);
    }, [isSubmitting]);
    

       

            
return (

    
   
        <div className={style.container}>
            <div className={style.leftSide}>
            <div className={style.debateBox}>
            <div className={style.header}>
                <img className={style.headerImage} src={placeholder}/>
                <div className={style.headerMain}>
                <div className={style.headerRow}>
                <h2 className={style.headerTitle}>{debateInfo.title}</h2>
                <h3 className={style.topicDate}>{debateInfo.date}</h3>
                </div>
                <div className={style.headerRow}>
                    <h4 className={style.topicType}>{debateInfo.type}</h4>
                    <h4 className={style.pointValue}>{debateInfo.points}</h4>
                    <h4 className={style.timeRemaining}>{debateInfo.remaining}</h4>
                </div>
                </div>
            </div>
            <div className={style.topicDescription}>
                {debateInfo.details}
            </div>
            <div className={style.footer}>
                <h5 className={style.answers}>14 Answers</h5>
                <h5 className={style.votes}>122 Votes</h5>
                <div className={style.rectButton} onClick={()=>{buttonHandler()}}>
                    Submit Response
                </div>
                
            </div>
        </div>
        <div className={style.answersBox}>
            <div className={style.answersHeader}>
            <h2 className={style.answersHeaderTitle}>Responses</h2>
            </div>
            <div className={style.answersContainer}>
                <Standard/>
                <Standard/>
                <Standard/>
                <Standard/>
            </div>
        </div>
        </div>
        <RightBar/>
        {isSubmitting && <SubmitModal close={buttonHandler}/>}
        </div>
      
    
  )
  }
  
import React, { Component } from 'react'

import style from './style.module.css'

import { MyPlayerCard } from '../MyPlayerCard'
import { TrackingCard } from '../TrackingCard'


class RightBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
        
    }

    componentDidMount = async () => {

       
      }

  
   

    render () {

       

            
return (

    
   
        <div className={style.container}>
            <MyPlayerCard/>
            <TrackingCard/>
        </div>
      
    
  )
  }
  }

export default RightBar
import React, { Component } from 'react'

import style from './style.module.css'




class TrackingCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
          leaders:[{name: 'ThatRick2k', value: 200},
          {name: 'Karl', value: 200}],
        }
        
    }

    componentDidMount = async () => {

       
      }

    render () {


            
return (

        <div className={style.container}>
            <h2 className={style.headerTitle}>
                Leaderboard
            </h2>
            <div className={style.leaderList}>
                {this.state.leaders.map((item, index) => {
                  return <div className={style.row}
                  ><h3 className={style.leaderName}>
                    {item.name}
                  </h3>
                  <h4 className={style.leaderPoints}>
                    {item.value}
                  </h4>
                  </div>
              })
            }
            </div>
        </div>
       
  )
  }
  }

export default TrackingCard
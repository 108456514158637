import React, { Component } from 'react'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";

import style from './style.module.css'


const logoSrc= 'https://cdn.iconscout.com/icon/free/png-256/debate-6-542541.png'

class TopNav extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

    }

    componentDidMount = async () => {


    }

    render() {

        return (

            <div className={style.container}>
                 <Link className={style.logoCenter} to='/'>
                <img src={logoSrc} className={style.logoCenter} style={{left:0}}/>
                </Link>
            </div>

        )
    }
}

export default TopNav
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

import { Home, Debate } from './pages'
import { TopNav } from './components'

export default function App() {
  return (
    <Router>
      <div style={{width:'100vw', height:'100vh', position:'absolute', top:'0px'}}>
        
        <TopNav/>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/debates/:topicId">
            <Debate/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}



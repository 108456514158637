import React, { Component } from 'react'

import style from './style.module.css'

import { MainFeed, RightBar } from '../../components'



class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
        
    }

    componentDidMount = async () => {

       
      }

    render () {


            
return (

        <div className={style.container}>
            <MainFeed/>
            <RightBar/>
        </div>
       
  )
  }
  }

export default Home
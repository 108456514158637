import React, { Component } from 'react'

import style from './style.module.css'

import { Standard } from './Standard'


class SubmitModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
        
    }

    componentDidMount = async () => {

       
      }

  
   

    render () {

       

            
return (

    
   
        <div className={style.container}>
            <div className={style.content}>
                <h2 className={style.title}>
                    Submit Response
                </h2>
                <div className={style.closeButton}
                onClick={()=>{this.props.close()}}>
                    X
                </div>
                <Standard/>
            </div>
        </div>
      
    
  )
  }
  }

export default SubmitModal
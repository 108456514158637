import React, { Component } from 'react'

import style from './style.module.css'


class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
        
    }

    componentDidMount = async () => {

       
      }

  
   

    render () {

       

            
return (

    
   
        <div className={style.container}>
           <h3 className={style.title}>
               Response
               </h3>
            <textarea className={style.textBox} placeholder="Answer">
            </textarea>
            <div class={style.rectButton}>
                Submit
                </div>
        </div>
      
    
  )
  }
  }

export default Home
import React, { Component } from 'react'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";

import style from './style.module.css'



import { Topic } from '../'

import { Debate } from '../../pages'


class MainFeed extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

    }

    componentDidMount = async () => {


    }

    render() {

        return (
            <div>
            <div className={style.container}>
            
           
          
          
        
                <Topic
                title={'Ninja Turtle Occurences in the League'}
                date={'July 10th, 2021'}
                type={'Written Answer'}
                points={'20 CP | 2 CP'}
                remaining={'14 hours'}
                details={'Given the available evidence, who is more likely to test positive for Ninja Turtle DNA: Draymond Green, or Russell Westbrook?'}
                topicId={'1'}
                >
                </Topic>
                
               
          
        
                <Topic
                title={'AD for Dame'}
                date={'July 10th, 2021'}
                type={'Written Answer'}
                points={'20 CP | 2 CP'}
                remaining={'14 hours'}
                details={'Is Anthony Davis for Lillard a really dumb trade, or a really really dumb trade?'}
                topicId={'1'}
                >
                
                </Topic>
                
            </div>
            </div>
            

        )
    }
}

export default MainFeed
import React, { Component } from 'react'

import style from './style.module.css'

import { MainFeed, RightBar } from '../../components'



class MyPlayerCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
        
    }

    componentDidMount = async () => {

       
      }

    render () {


            
return (

        <div className={style.container}>
            <h2 className={style.headerTitle}>
                ThatRick2k
            </h2>
            <div className={style.barContainer}>
                <div className={style.barValue}/>
                <div className={style.barNumbers}>
                        2300/3000
                    </div>
            </div>
            <h3 className={style.rankTitle}>
                Rookie
            </h3>
        </div>
       
  )
  }
  }

export default MyPlayerCard